import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons"
import "@fontsource/merriweather-sans"
import "@fontsource/open-sans"
import "../scss/site.scss"
import LogoImage from "../../static/images/ibdmate-logo.svg"
import Sidebar from "./Sidebar"
const ListLink = props => (
  <li>
    <Link to={props.to} activeClassName="selected">{props.children}</Link>
  </li>
)

export default function Layout({ children }) {
  return (
    <div className="page">
      <header className="header">
        <div className="wrapper">
          <Link to="/" id="logo">
            <img src={LogoImage} width="193" alt="IBDmate" />
          </Link>
          <div id="full-nav">
            <ul>
              <ListLink to="/">Home</ListLink>
              <ListLink to="/explore/">Explore</ListLink>
              {/*<ListLink to="/healthcare">For healthcare</ListLink>*/}
              <ListLink to="/about/">About</ListLink>
              <ListLink to="/support/">Support</ListLink>
              <ListLink to="/contact/">Contact</ListLink>
              {/*<li><Link to="/subscribe/" className="btn btn-purple">Request early access</Link></li>*/}
            </ul>
          </div>
          <div id="mobile-nav">
            <Sidebar />
          </div>
        </div>
      </header>
      {children}
      <footer className="footer">
        <ul>
          <ListLink to="/about/">About us</ListLink>
          <ListLink to="/support/">Support</ListLink>
          <li>
            <a href="https://dashboard.ibdmate.com" target="_blank" rel="noreferrer">
            HCP Dashboard <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </li>
        </ul>
        <ul>
          <ListLink to="/terms/">Terms of use</ListLink>
          <ListLink to="/privacy/">Privacy policy</ListLink>
        </ul>
        <ul>
          <li><a href="https://www.facebook.com/IBDmate" target="_blank" rel="noreferrer">Facebook</a></li>
          <li><a href="https://www.instagram.com/ibdmate/" target="_blank" rel="noreferrer">Instagram</a></li>
          <li><a href="https://www.twitter.com/ibdmate/" target="_blank" rel="noreferrer">Twitter</a></li>
        </ul>
      </footer>
      <p className="copyright">{(new Date().getFullYear())} &copy; <a href="https://www.ibdrelief.com" target="_blank" rel="noreferrer">IBDrelief</a></p>
    </div>
  )
}
