import React from 'react';
import { Link } from "gatsby"
import { slide as Menu } from 'react-burger-menu';

export default props => {
  return (
    <Menu right>
      <Link className="menu-item" activeClassName="selected" to="/">Home</Link>
      <Link className="menu-item" activeClassName="selected" to="/explore/">Explore IBDmate</Link>
      {/*<Link className="menu-item" activeClassName="selected" to="/healthcare">For healthcare</Link>*/}
      <Link className="menu-item" activeClassName="selected" to="/about/">About us</Link>
      <Link className="menu-item" activeClassName="selected" to="/support/">Support</Link>
      <Link className="menu-item" activeClassName="selected" to="/contact/">Contact</Link>
      {/*<Link className="menu-item" activeClassName="selected" to="/subscribe/">Request early access</Link>*/}
    </Menu>
  );
};
